<template>
 


<div>
   <header class="vff-header">
      <div class="f-container">
       <!-- Add custom logo here -->
       
      </div>
    </header>


 <flow-form 
 v-if="emgeek != null"
      ref="flowform"
      v-on:complete="onComplete"
      v-on:submit="onSubmit"
      v-bind:questions="questions"
      v-bind:language="language"
        v-bind:standalone="true"
      style="z-index:2!important"
    >
    
    <!-- Custom content for the Complete/Submit screen slots in the FlowForm component -->
      <!-- We've overriden the default "complete" slot content -->
     <template v-slot:complete>
        <div class="f-section-wrap">
          <p>
            <span class="fh2">Thank you for sharing</span>
            <span class="f-section-text">
              You will be redirected to <a href="https://www.emailjobs.io" target="_blank">emailjobs.io</a> in a second or two...
              <br> if not, click the button below (you can ignore the warning)
            </span>
          </p>
          
        </div>  
      </template>

      <!-- We've overriden the default "completeButton" slot content -->
      <template v-slot:completeButton>
         <div class="f-submit" v-if="!submitted">
         <button 
          class="o-btn-action"
          ref="button"
          href="https://www.emailjobs.io"
          v-on:click="onSendData()"
          role="button"
          aria-label="Press to submit"
        >
         
            <span>Submit</span>
           </button>

         
        </div>

        <p class="text-success" v-if="submitted">Redirecting...</p>
      </template>
    </flow-form>
</div>
 

 
</template>

<script>
import { FlowForm, QuestionModel, QuestionType, ChoiceOption, LanguageModel, MatrixRow, MatrixColumn } from '@ditdot-dev/vue-flow-form';

/* const q = new URLSearchParams(window.location.search); */

import db from '../fb.js'
import { doc, getDoc, getDocs, setDoc,updateDoc, onSnapshot, collection, query,where } from 'firebase/firestore'
const q = new URLSearchParams(window.location.search);



export default {
  name: 'emailjobstest',
  props: {
    msg: String
  },
  components: {
      FlowForm
    },
     
    data() {
      return {
         headers: [
          {
            text: 'Campaign',
            align: 'start',
            sortable: true,
            value: 'campaignID',
          },
           { text: 'user', value: 'userID' },
          { text: 'sentiment', value: 'sentiment' },
           { text: 'negative', value: 'negative' },
           { text: 'positive', value: 'positive' },
         
        ],
        tagline:'',
        items:[],
        editedItem: {}, 
      itemsPerPageArray: [6, 8, 12],
       page: 1,
        itemsPerPage: 20,
      newCompany:true,
       filter: {},
       keys: [
          'jobtitle',
          'id'
        ],
        search: null,
      sortDesc: false,
      getcampaign:"2020-10-01",
        campaignsentiment:[],
        positives:'',
        negatives:'',
        positive:'',
        negative:'',
        campaign:'',
        emgeek:null,
        sentiment:'',
        q:'',
        title:'Tell me more',
        submitted: false,
        completed: false,
        language: new LanguageModel({
          // Your language definitions here (optional).
          // You can leave out this prop if you want to use the default definitions.
        }),
        questions1:[],
        questions2:'',
        questions: [ 
         new QuestionModel({
        answer: q.get('sentiment'),
        id: "sentiment",
        tagline: this.tagline,
        title: 'What do you think of our email?',
        required: true,
        subtitle:'',
        helpTextShow: false,
        type: QuestionType.MultipleChoice,
        multiple: false,
        options: [
        new ChoiceOption({
        label: '👍 I like it',
        value: 'positive'
        }),
        new ChoiceOption({
        label: '👎 I don\'t like it',
        value: 'negative'
        })
        ],
        jump: {
        positive: 'positive',
        negative: 'negative'
        }
        }),
        new QuestionModel({
        type: QuestionType.LongText,
        id: "positive",
        tagline: "Thank you sooo much for liking our email 🥳",
        title: 'Do you have comments, suggestions or feedback to add?',
        required: false,
        subtitle:'',
        helpTextShow: false,
        placeholder: 'Start typing here...',
        model: '',
          jump: {
              _other: '_submit',
             
            }
        }),
         new QuestionModel({
        type: QuestionType.LongText,
        id: "negative",
        tagline: "Sorry to hear that you did not like our email ",
        title: 'Do you have comments, suggestions or feedback to add?',
        required: false,
        subtitle:'',
        helpTextShow: false,
        placeholder: 'Start typing here...',
        model: '',
          jump: {
              _other: '_submit',
            }
        }),

        ]
      }
    },
    mounted() {

/* ?emgeek=jeffdewijs@gmail.com&sentiment=negative&utm_campaign=2020-10-01
 */
 
      document.addEventListener('keyup', this.onKeyListener)
    /*   this.getSentiment(); */
      this.getUID();
      this.getCampaign();
     this.check();  
    },
    beforeUnmount() {
      document.removeEventListener('keyup', this.onKeyListener)
    },
     watch:{
      submitted: function(){
        console.log(this.submitted)
        setTimeout(() => (    this.redirect() , 10000))
   
      } ,
        completed: function(){
        console.log(this.completed)
        if (this.completed) {  setTimeout(() => (    this.redirect() , 10000))}
     
      }  

    },
  
    methods: {

     
async check(){
        
         
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const emgeek = urlParams.get('emgeek');
const campaign = urlParams.get('utm_campaign');
const sentiment =  urlParams.get('sentiment');
const date = new Date();
const usercampaign = emgeek + '_' + campaign 

/* if (sentiment === 'positive'){ const positive = '1'; const negative = '0'};
if (sentiment === 'negative'){ const positive = '0' ; const negative = '1'};  */

   const feedback = {
    campaignID: campaign,
    userID: emgeek, 
    sentiment: sentiment,
    feedback:'',
    positive: this.positive,
    negative: this.negative
  } 


  const campaigndetails = {
    campaignID: this.campaign,
    created: date
  }

const emgeekdetails = {
    userID: this.emgeek,
    created: date
  }
if (emgeek != null) {
// fetching a single document (& realtime) -> did the user already add sentiment for this campaign? check doc
const docRef = doc(db, "emailgeeksio", "emailFB", "sentiment", `${usercampaign}`)
// get the data
const docSnap = await getDoc(docRef);
// check if exists
if (docSnap.exists()) {
  console.log("feedback was already given");
} else {
 /*  console.log("feedback was never given, creating docs"); */

//add feedback to sentiment collection 
await setDoc(doc(db, "emailgeeksio", "emailFB", "sentiment", `${usercampaign}`), feedback);

//add campaign info to campaigns collection
await setDoc(doc(db, "emailgeeksio", "emailFB", "campaigns", `${campaign}`), campaigndetails);

//add user info to users collection
await setDoc(doc(db, "emailgeeksio", "emailFB", "users", `${emgeek}`), emgeekdetails);


/* await setDoc(doc(db, "cities", "LA"), {
  name: "Los Angeles",
  state: "CA",
  country: "USA"
}); */

/* this.redirect() */




}

} else{ return console.log('no info :p') ,  this.redirect() }
      },


redirect(){

  const domain = 'https://www.emailjobs.io' 
setTimeout(() => (window.location.replace(domain) , 3000))
 }, 


        getUID () {
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const emgeek = urlParams.get('emgeek');

/* if (emgeek) return (this.questions[0].answer = emgeek) & (this.questions[0].answered = true);
 */
if (emgeek) return this.emgeek = emgeek, this.getSentiment();
},
      getCampaign () {
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const campaign = urlParams.get('utm_campaign');

if (campaign != 0) return this.campaign = campaign;
},
      getSentiment () {
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const sentiment =  urlParams.get('sentiment');
setTimeout(()=>{
   this.$refs.flowform.goToQuestion(sentiment)
},1000);
 
if (sentiment == 'positive') return (this.sentiment = 'positive') & (this.positive = '1') & (this.negative = '0');
if (sentiment == 'negative') return (this.sentiment = 'negative') & (this.positive = '0') & (this.negative = '1'); 



},
      onKeyListener($event) {
        // We've overriden the default "complete" slot so
        // we need to implement the "keyup" listener manually.
        if ($event.key === 'Enter' && this.completed && !this.submitted) {
          // Set `submitted` to true so the form knows not to allow back/forward
          // navigation anymore.
       
          this.onSendData()
        }
      },
      /* eslint-disable-next-line no-unused-vars */
      onComplete(completed, questionList) {
        // This method is called whenever the "completed" status is changed.
        this.completed = completed
      },
       onAnswer(questionAnswered) {
  // Handle answer event.
  console.log(questionAnswered)
},
onStep(activeQuestionId, activeQuestion) {
  // Handle step event.
  console.log(activeQuestionId, activeQuestion)
},
      /* eslint-disable-next-line no-unused-vars */
      onSubmit(questionList) {
        // This method will only be called if you don't override the
        // completeButton slot.
        this.onSendData()
      },
      
   async onSendData() {
        // Set `submitted` to true so the form knows not to allow back/forward
        // navigation anymore.
        this.$refs.flowform.submitted = true
        this.submitted = true
        /* eslint-disable-next-line no-unused-vars */
        const data = this.getData()
       
        
console.log(data)
          
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const emgeek = urlParams.get('emgeek');
const campaign = urlParams.get('utm_campaign');
const sentiment =  urlParams.get('sentiment');
const date = new Date();
const usercampaign = emgeek + '_' + campaign 

/* if (sentiment === 'positive'){ const positive = '1'; const negative = '0'};
if (sentiment === 'negative'){ const positive = '0' ; const negative = '1'};  */

   const feedback = {
    campaignID: campaign,
    userID: emgeek, 
    sentiment: sentiment,
    feedback:data.answers[2],
    positive: this.positive,
    negative: this.negative,
    created:date
  } 


  const campaigndetails = {
    campaignID: this.campaign,
    created: date
  }

const emgeekdetails = {
    userID: this.emgeek,
    created: date
  }




//updateDoc feedback to sentiment collection 
await updateDoc(doc(db, "emailgeeksio", "emailFB", "sentiment", `${usercampaign}`), feedback);

//updateDoc campaign info to campaigns collection
await updateDoc(doc(db, "emailgeeksio", "emailFB", "campaigns", `${campaign}`), campaigndetails);

//updateDoc user info to users collection
await updateDoc(doc(db, "emailgeeksio", "emailFB", "users", `${emgeek}`), emgeekdetails); 
       
        setTimeout(() => ( this.redirect() , 3000))  


        /*
          You can use Fetch API to send the data to your server, eg.:
          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
        */
      },
     /*  getData() {
        const data = {
          questions: [],
          answers: []
        }
        this.questions.forEach(question => {
          if (question.title) {
            let answer = question.answer
            if (typeof answer === 'object') {
              answer = answer.join(', ')
            }
            data.questions.push(question.title)
            data.answers.push(answer)
          }
        })
        return data
      }, */
       getData() {
        const data = {
          questions: [],
          answers: []
        }
        this.questions.forEach(question => {
          if (question.title) {
            let answer = question.answer
            if (Array.isArray(answer)) {
              answer = answer.join(', ')
            }
            data.questions.push(question.title)
            data.answers.push(answer)
          }
        })
        return data, console.log(answers)
      },
addFeedback(){
  
  const feedback = {
    campaign: this.campaign,
    user: this.emgeek, 
    sentiment: this.sentiment,
    positive: this.positive,
    negative: this.negative
  }
 db.collection('emailgeeksio').doc('emailFB').collection('users').add(feedback)
},
addFeedbackSum(){

  const feedback = {
    campaign: this.campaign,
    user: this.emgeek, 
    sentiment: this.sentiment,
    positive: this.positive,
    negative: this.negative
  }
    
      db.collection('emailgeeksio').doc('emailFB').collection('users').add(feedback)
},
feedbackonMounted(){
  const date = new Date()
  const sentiment = this.emgeek + '_' + this.campaign
  const user = this.emgeek
  const campaign = this.campaign

  const feedback = {
    campaignID: this.campaign,
    userID: this.emgeek, 
    sentiment: this.sentiment,
    positive: this.positive,
    negative: this.negative
  } 

  const campaigndetails = {
    campaignID: this.campaign,
    created: date
  }

const emgeekdetails = {
    userID: this.emgeek,
    created: date
  }

  db.collection('emailgeeksio').doc('emailFB').collection('sentiment').doc(sentiment).set(feedback)
  db.collection('emailgeeksio').doc('emailFB').collection('campaigns').doc(campaign).set(campaigndetails)
  db.collection('emailgeeksio').doc('emailFB').collection('users').doc(user).set(emgeekdetails)
},
   /*  beforeDestroy(){
      this.addFeedbackSum();
    } */
  },
   computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== `user`)
      },
      totalfeedback(){
         let sentiment = this.campaignsentiment
          let negative = sentiment.filter(sentiment => sentiment.negative === 1)
          let positive = sentiment.filter(sentiment => sentiment.positive === 1)

     return  negative.length + positive.length

      
     
      },
       totalpositive(){
           let sentiment = this.campaignsentiment
            let positive = sentiment.filter(sentiment => sentiment.positive === 1)
     return   positive.length
      },
       totalnegative(){
           let sentiment = this.campaignsentiment
          let negative = sentiment.filter(sentiment => sentiment.negative === 1)
     return   negative.length
      },
      getCampaignsentiment: function(){
        let sentiment = this.campaignsentiment
      
       if (this.getcampaign != ''){
         let result = sentiment.filter(sentiment => sentiment.campaignID == this.getcampaign)
         return result
    }
  
   return sentiment
      }
    },
     created: async function () {
    
      },


  /* const date = new Date()
  const user = this.emgeek
  const campaign = this.campaign

  const feedback = {
    campaignID: this.campaign,
    userID: this.emgeek, 
    sentiment: this.sentiment,
    positive: this.positive,
    negative: this.negative
  } 

  const campaigndetails = {
    campaignID: this.campaign,
    created: date
  }

const emgeekdetails = {
    userID: this.emgeek,
    created: date
  } */

 

}
/* function getByCampaignID(filterCampaigns, getcampaign) {
  if (getcampaign == '') return filterCampaigns
  return filterCampaigns.filter(({getcampaign}) => getcampaign.includes(getcampaign))
} */

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
  /* Import one of the Vue Flow Form CSS themes (optional) */
/*   @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-green.css';  */
 /*  @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css';  */
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css'; */
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal-jeff.css'; 
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.f-text{
  color:black!important;
}

.vff{
  color:black!important;
}

.f-required {
  display: inline; /* or display: none; to turn off */
}
</style>
