<template>
 

<!-- 
 <v-data-table :headers="headers" :items="campaignsentiment" :search="search"
                    class="elevation-1" >
                          
         <template v-slot:item.actions="{item}">
      <v-icon
        small
        class="mr-2"
        @click="editJob(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-card><v-card-title>no jobs? check the db </v-card-title></v-card>
    </template>
  </v-data-table>

    
<v-sheet>
<v-card>
  <v-card-text v-if="this.sentiment = 'positive'">
    Thank you! Any other comments, suggestions or feedback to add?
</v-card-text>


</v-card>

</v-sheet> -->

<div>
   <header class="vff-header">
      <div class="f-container">
       <!-- Add custom logo here -->
        <svg class="f-logo" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMid meet" viewBox="0 0 35.606 11.211">
          <path d="M.134.837H3.21V8.01h4.203v2.18H.134V.837z"/>
          <path d="M11.875.59c1.48 0 2.668.448 3.567 1.344s1.35 2.052 1.35 3.47c0 1.48-.445 2.7-1.336 3.632S13.38 10.45 11.9 10.45c-1.678 0-2.954-.54-3.827-1.622-.717-.9-1.08-2.022-1.09-3.397-.01-1.36.39-2.484 1.193-3.374C9.06 1.08 10.292.59 11.875.59zm0 2.283c-.563 0-1.003.222-1.323.662-.338.467-.507 1.124-.507 1.972 0 .865.162 1.524.487 1.978a1.58 1.58 0 0 0 1.369.682c.588 0 1.04-.223 1.355-.668s.474-1.07.474-1.875c0-1.834-.62-2.75-1.855-2.75z"/>
          <path d="M21.565 7.078V5.055h4.217v5.163h-1.986l-.13-.908c-.693.76-1.617 1.142-2.777 1.142-1.383 0-2.488-.437-3.313-1.3s-1.243-2.03-1.252-3.464c-.01-1.462.385-2.65 1.18-3.567.875-1.012 2.11-1.518 3.7-1.518 1.21 0 2.207.303 3 .907s1.264 1.457 1.447 2.556h-2.92c-.207-.787-.73-1.182-1.57-1.182-.553 0-.988.236-1.303.707s-.475 1.153-.475 2.043c0 1.695.652 2.542 1.96 2.542.363 0 .695-.103.998-.306a1.29 1.29 0 0 0 .572-.784h-1.35v.002z"/>
          <path d="M30.556.59c1.48 0 2.668.448 3.568 1.344s1.348 2.052 1.348 3.47c0 1.48-.443 2.7-1.336 3.632S32.06 10.45 30.58 10.45c-1.678 0-2.953-.54-3.826-1.622-.72-.892-1.082-2.022-1.1-3.398-.008-1.357.39-2.48 1.193-3.372C27.74 1.08 28.974.59 30.556.59zm0 2.283c-.563 0-1.002.222-1.322.662-.336.467-.506 1.125-.506 1.972 0 .865.162 1.524.486 1.978s.78.682 1.37.682 1.04-.223 1.355-.668.475-1.07.475-1.875c-.002-1.834-.62-2.75-1.857-2.75z"/>
        </svg>
      </div>
    </header>

  <!--    <v-data-table :headers="headers" :items="campaignsentiment" :search="search"
                    class="elevation-1" >
                          
         <template v-slot:item.actions="{item}">
      <v-icon
        small
        class="mr-2"
        @click="editJob(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-card><v-card-title>no jobs? check the db </v-card-title></v-card>
    </template>
  </v-data-table>

    
<v-sheet>
<v-card>
  <v-card-text v-if="this.sentiment = 'positive'">



</v-card-text>


</v-card>

</v-sheet> -->

 <flow-form 
      ref="flowform"
      v-on:complete="onComplete"
      v-on:submit="onSubmit"
      v-bind:questions="questions"
      v-bind:language="language"
        v-bind:standalone="true"
      style="z-index:2!important"
    >
    
    <!-- Custom content for the Complete/Submit screen slots in the FlowForm component -->
      <!-- We've overriden the default "complete" slot content -->
     <template v-slot:complete>
        <div class="f-section-wrap">
          <p>
            <span class="fh2">Thank you for sharing</span>
            <span class="f-section-text">
              You will be redirected to <a href="https://www.emailjobs.io" target="_blank">emailjobs.io</a> in a second or two...
              <br> if not, click the button below (you can ignore the warning)
            </span>
          </p>
          
        </div>  
      </template>

      <!-- We've overriden the default "completeButton" slot content -->
      <template v-slot:completeButton>
         <div class="f-submit" v-if="!submitted">
         <button 
          class="o-btn-action"
          ref="button"
          href="https://www.emailjobs.io"
          v-on:click="onSendData()"
          role="button"
          aria-label="Press to submit"
        >
         
            <span>Redirect me</span>
           </button>

         
        </div>

        <p class="text-success" v-if="submitted">Redirecting...</p>
      </template>
    </flow-form>
</div>
 

 
</template>

<script>
import { FlowForm, QuestionModel, QuestionType, ChoiceOption, LanguageModel, MatrixRow, MatrixColumn } from '@ditdot-dev/vue-flow-form';

/* const q = new URLSearchParams(window.location.search); */

import db from '../fb.js'
import { doc, getDoc, getDocs, setDoc, onSnapshot, collection, query,where } from 'firebase/firestore'
const q = new URLSearchParams(window.location.search);



export default {
  name: 'test',
  props: {
    msg: String
  },
  components: {
      FlowForm
    },
     
    data() {
      return {
         headers: [
          {
            text: 'Campaign',
            align: 'start',
            sortable: true,
            value: 'campaignID',
          },
           { text: 'user', value: 'userID' },
          { text: 'sentiment', value: 'sentiment' },
           { text: 'negative', value: 'negative' },
           { text: 'positive', value: 'positive' },
         
        ],
        tagline:'',
        items:[],
        editedItem: {}, 
      itemsPerPageArray: [6, 8, 12],
       page: 1,
        itemsPerPage: 20,
      newCompany:true,
       filter: {},
       keys: [
          'jobtitle',
          'id'
        ],
        search: null,
      sortDesc: false,
      getcampaign:"2020-10-01",
        campaignsentiment:[],
        positives:'',
        negatives:'',
        positive:'',
        negative:'',
        campaign:'',
        emgeek:'',
        sentiment:'',
        q:'',
        title:'Tell me more',
        submitted: false,
        completed: false,
        language: new LanguageModel({
          // Your language definitions here (optional).
          // You can leave out this prop if you want to use the default definitions.
        }),
        questions1:[],
        questions2:'',
        questions: [ 
         new QuestionModel({
        answer: q.get('sentiment'),
        id: "sentiment",
        tagline: this.tagline,
        title: 'What do you think of our email?',
        required: true,
        subtitle:'',
        helpTextShow: false,
        type: QuestionType.MultipleChoice,
        multiple: false,
        options: [
        new ChoiceOption({
        label: '👍 I like it',
        value: 'positive'
        }),
        new ChoiceOption({
        label: '👎 I don\'t like it',
        value: 'negative'
        })
        ],
        jump: {
        positive: 'positive',
        negative: 'negative'
        }
        }),
        new QuestionModel({
        type: QuestionType.LongText,
        id: "positive",
        tagline: "Thank you sooo much for liking our email 🥳",
        title: 'Do you have comments, suggestions or feedback to add?',
        required: false,
        subtitle:'',
        helpTextShow: false,
        placeholder: 'Start typing here...',
        model: '',
          jump: {
              _other: '_submit',
             
            }
        }),
         new QuestionModel({
        type: QuestionType.LongText,
        id: "negative",
        tagline: "Sorry to hear that you did not like our email ",
        title: 'Do you have comments, suggestions or feedback to add?',
        required: false,
        subtitle:'',
        helpTextShow: false,
        placeholder: 'Start typing here...',
        model: '',
          jump: {
              _other: '_submit',
            }
        }),

        ]
      }
    },
    mounted() {

/* ?emgeek=jeffdewijs@gmail.com&sentiment=negative&utm_campaign=2020-10-01
 */
 
      document.addEventListener('keyup', this.onKeyListener)
      this.getSentiment();
      this.getUID();
      this.getCampaign();
  /*     this.getformdata(); */
      this.getformdata2(); 
     /*   this.getformdata3(); */
   /*    this.check();  */
  /*   this.feedbackonMounted();  */
    },
    beforeUnmount() {
      document.removeEventListener('keyup', this.onKeyListener)
    },
    firestore () {
    return {
      campaignsentiment: db.collection('emailgeeksio').doc('emailFB').collection("sentiment"),
    /*   positives: db.collection('emailgeeksio').doc('emailFB').collection("sentiment").where("positive", "==", 1),
      negatives: db.collection('emailgeeksio').doc('emailFB').collection("sentiment").where("negative", "==", 1), */
    }},
     watch:{
      submitted: function(){
        console.log(this.submitted)
       this.redirect()
      } 

    },
    /* 
    beforeRouteEnter (to, from, next) {


const docRef = doc(db, "forms", "kEr4fE75yd7dkLWObeKb");
const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  console.log("Document data:", docSnap.data());
   this.questions = docSnap.data().questions
} else {
  // doc.data() will be undefined in this case
 
}


      db.collection('jobs').where('string', '==', to.params.string).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          next(vm => {
            vm.jobid=doc.data().jobid
            vm.title=doc.data().title
            vm.description=doc.data().description
            vm.salaryrangemin=doc.data().salaryrangemin
            vm.salaryrangemax=doc.data().salaryrangemax
            vm.currency=doc.data().currency
            vm.hours=doc.data().hours
            vm.edu=doc.data().edu
            vm.exp=doc.data().exp
            vm.esp=doc.data().esp
            vm.joblevel=doc.data().joblevel
            vm.employmenttype=doc.data().employmenttype
            vm.techstack=doc.data().techstack
            vm.certifications=doc.data().certifications
            vm.remote=doc.data().remote
            vm.link=doc.data().link
            vm.string=doc.data().string
            vm.active=doc.data().active
            vm.promo=doc.data().promo
            vm.promotype=doc.data().promotype
            vm.perks=doc.data().perks
            vm.scripting=doc.data().scripting
            vm.timeperks=doc.data().timeperks
            vm.holidays=doc.data().holidays
            vm.officeperks=doc.data().officeperks
            vm.otherperks=doc.data().otherperks
            vm.devperks=doc.data().devperks
            vm.geolocation=doc.data().geolocation
            vm.remote=doc.data().remote
            vm.remotetype=doc.data().remotetype
            vm.companyid=doc.data().companyid
            vm.companyname=doc.data().companyname
            vm.companycountry=doc.data().companycountry
            vm.companycity=doc.data().companycity
            vm.companyaddress=doc.data().companyaddress
            vm.companypostalcode=doc.data().companypostalcode
            vm.companywebsite=doc.data().companywebsite
            vm.companyinsta=doc.data().companyinsta
            vm.companylinkedin=doc.data().companylinkedin
            vm.companytwitter=doc.data().companytwitter
            vm.companyyoutube=doc.data().companyyoutube
            vm.companylogo=doc.data().companylogo
            vm.companyindustry=doc.data().companyindustry
            vm.companyemployees=doc.data().companyemployees
            vm.createdAt=doc.data().createdAt.toDate()
            vm.createdAt2=doc.data().createdAt
          })
        })
       
      })
    },  */
    async beforeRouteEnter (to, from, next) {
       
const docRef = doc(db, "forms", "kEr4fE75yd7dkLWObeKb");
const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  console.log("Document data:", docSnap.data());
  vm.questions = docSnap.data().questions1
} else {
  // doc.data() will be undefined in this case
  console.log('form does not exist')
}
      },
    methods: {

      async getformdata(){

const docRef = doc(db, "forms", "kEr4fE75yd7dkLWObeKb");
const docSnap = await getDoc(docRef);
const questions = this.questions1
if (docSnap.exists()) {
  console.log("Document data:", docSnap.data());
  questions.push(docSnap.data().questions)
} else {
  // doc.data() will be undefined in this case
  console.log('form does not exist')
}

      },
 async getformdata3(){

const docRef = doc(db, "forms", "kEr4fE75yd7dkLWObeKb");
const docSnap = await getDoc(docRef);



if (docSnap.exists()) {
  console.log("Document data:", docSnap.data());
/*  const q = docSnap.data().questions[0]
   const str = JSON.stringify(q)
  console.log(str) */
 
  let quesArray = []
   this.questions1 = quesArray
const questions = docSnap.data().questions
questions.forEach(question =>{ 
 
  quesArray.push(question)
})

} else {
  // doc.data() will be undefined in this case
  console.log('form does not exist')
}

      },
      async getformdata2(){

const colRef = collection(db, 'forms')
const q = query(colRef, where("customer", "==", "emailgeeksio"))
 let answer1 = null
// realtime collection data
const unsubCol = onSnapshot(q, (snapshot) => {
  let ques = []
  this.questions2 = ques
  
  snapshot.docs.forEach(doc => {
    
const questions = doc.data().vragen
const answer1 = questions[0].answer
const type1 = questions[0].type

questions.forEach(question =>{
ques.push(new QuestionModel({
        id:question.id,
        title: question.title,
        helpTextShow:question.helpTextShow,
        answer: question.answer, 
        answered:question.answered,
        required:question.required,
        subtitle:question.subtitle,
        type:QuestionType.MultipleChoice,
        index:question.index,
        multiple:question.multiple,
        jump:question.jump,
        options: question.options.forEach(option =>{
          new ChoiceOption({
            label: option.label,
            value: option.value
          })
        }),
       

        }))
     })


   /*  ques.push({ ...doc.data(), id: doc.id }) */
  })
 return {unsubCol, ques}
})
/*  {unsubCol, answer1} {unsubCol, answer1} */ 
      },

async check(){
        
         
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const emgeek = urlParams.get('emgeek');
const campaign = urlParams.get('utm_campaign');
const sentiment =  urlParams.get('sentiment');
const date = new Date();
const usercampaign = emgeek + '_' + campaign 

/* if (sentiment === 'positive'){ const positive = '1'; const negative = '0'};
if (sentiment === 'negative'){ const positive = '0' ; const negative = '1'};  */

   const feedback = {
    campaignID: campaign,
    userID: emgeek, 
    sentiment: sentiment,
    positive: this.positive,
    negative: this.negative,
    created: date

  } 


  const campaigndetails = {
    campaignID: this.campaign,
    created: date
  }

const emgeekdetails = {
    userID: this.emgeek,
    created: date
  }

// fetching a single document (& realtime) -> did the user already add sentiment for this campaign? check doc
const docRef = doc(db, "emailgeeksio", "emailFB", "sentiment", `${usercampaign}`)
// get the data
const docSnap = await getDoc(docRef);
// check if exists
if (docSnap.exists()) {
  console.log("feedback was already given");
} else {
  console.log("feedback was never given, creating docs");

//add feedback to sentiment collection 
await setDoc(doc(db, "emailgeeksio", "emailFB", "sentiment", `${usercampaign}`), feedback);

//add campaign info to campaigns collection
await setDoc(doc(db, "emailgeeksio", "emailFB", "campaigns", `${campaign}`), campaigndetails);

//add user info to users collection
await setDoc(doc(db, "emailgeeksio", "emailFB", "users", `${emgeek}`), emgeekdetails);


/* await setDoc(doc(db, "cities", "LA"), {
  name: "Los Angeles",
  state: "CA",
  country: "USA"
}); */





}
      },


redirect(){

  const domain = 'https://www.emailjobs.io' 
setTimeout(() => (window.location.replace(domain) , 3000))
 }, 


        getUID () {
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const emgeek = urlParams.get('emgeek');

/* if (emgeek) return (this.questions[0].answer = emgeek) & (this.questions[0].answered = true);
 */
if (emgeek) return this.emgeek = emgeek;
},
      getCampaign () {
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const campaign = urlParams.get('utm_campaign');

if (campaign != 0) return this.campaign = campaign;
},
      getSentiment () {
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const sentiment =  urlParams.get('sentiment');
setTimeout(()=>{
   this.$refs.flowform.goToQuestion(sentiment)
},1000);
 
if (sentiment == 'positive') return (this.sentiment = 'positive') & (this.positive = '1') & (this.negative = '0');
if (sentiment == 'negative') return (this.sentiment = 'negative') & (this.positive = '0') & (this.negative = '1'); 



},
      onKeyListener($event) {
        // We've overriden the default "complete" slot so
        // we need to implement the "keyup" listener manually.
        if ($event.key === 'Enter' && this.completed && !this.submitted) {
          // Set `submitted` to true so the form knows not to allow back/forward
          // navigation anymore.
          this.$refs.flowform.submitted = true
          this.onSendData()
        }
      },
      /* eslint-disable-next-line no-unused-vars */
      onComplete(completed, questionList) {
        // This method is called whenever the "completed" status is changed.
        this.completed = completed
      },
      /* eslint-disable-next-line no-unused-vars */
      onSubmit(questionList) {
        // This method will only be called if you don't override the
        // completeButton slot.
        this.onSendData()
      },
      
      onSendData() {
        this.submitted = true
        /* eslint-disable-next-line no-unused-vars */
        const data = this.getData()

        console.log(data)
       
        setTimeout(() => ( this.redirect() , 3000)) 


        /*
          You can use Fetch API to send the data to your server, eg.:
          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
        */
      },
     /*  getData() {
        const data = {
          questions: [],
          answers: []
        }
        this.questions.forEach(question => {
          if (question.title) {
            let answer = question.answer
            if (typeof answer === 'object') {
              answer = answer.join(', ')
            }
            data.questions.push(question.title)
            data.answers.push(answer)
          }
        })
        return data
      }, */
       getData() {
        const data = {
          questions: [],
          answers: []
        }
        this.questions.forEach(question => {
          if (question.title) {
            let answer = question.answer
            if (Array.isArray(answer)) {
              answer = answer.join(', ')
            }
            data.questions.push(question.title)
            data.answers.push(answer)
          }
        })
        return data
      },
addFeedback(){
  
  const feedback = {
    campaign: this.campaign,
    user: this.emgeek, 
    sentiment: this.sentiment,
    positive: this.positive,
    negative: this.negative
  }
 db.collection('emailgeeksio').doc('emailFB').collection('users').add(feedback)
},
addFeedbackSum(){

  const feedback = {
    campaign: this.campaign,
    user: this.emgeek, 
    sentiment: this.sentiment,
    positive: this.positive,
    negative: this.negative
  }
    
      db.collection('emailgeeksio').doc('emailFB').collection('users').add(feedback)
},
feedbackonMounted(){
  const date = new Date()
  const sentiment = this.emgeek + '_' + this.campaign
  const user = this.emgeek
  const campaign = this.campaign

  const feedback = {
    campaignID: this.campaign,
    userID: this.emgeek, 
    sentiment: this.sentiment,
    positive: this.positive,
    negative: this.negative
  } 

  const campaigndetails = {
    campaignID: this.campaign,
    created: date
  }

const emgeekdetails = {
    userID: this.emgeek,
    created: date
  }

  db.collection('emailgeeksio').doc('emailFB').collection('sentiment').doc(sentiment).set(feedback)
  db.collection('emailgeeksio').doc('emailFB').collection('campaigns').doc(campaign).set(campaigndetails)
  db.collection('emailgeeksio').doc('emailFB').collection('users').doc(user).set(emgeekdetails)
},
   /*  beforeDestroy(){
      this.addFeedbackSum();
    } */
  },
   computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== `user`)
      },
      totalfeedback(){
         let sentiment = this.campaignsentiment
          let negative = sentiment.filter(sentiment => sentiment.negative === 1)
          let positive = sentiment.filter(sentiment => sentiment.positive === 1)

     return  negative.length + positive.length

      
     
      },
       totalpositive(){
           let sentiment = this.campaignsentiment
            let positive = sentiment.filter(sentiment => sentiment.positive === 1)
     return   positive.length
      },
       totalnegative(){
           let sentiment = this.campaignsentiment
          let negative = sentiment.filter(sentiment => sentiment.negative === 1)
     return   negative.length
      },
      getCampaignsentiment: function(){
        let sentiment = this.campaignsentiment
      
       if (this.getcampaign != ''){
         let result = sentiment.filter(sentiment => sentiment.campaignID == this.getcampaign)
         return result
    }
  
   return sentiment
      }
    },
     created: async function () {
    
      },


  /* const date = new Date()
  const user = this.emgeek
  const campaign = this.campaign

  const feedback = {
    campaignID: this.campaign,
    userID: this.emgeek, 
    sentiment: this.sentiment,
    positive: this.positive,
    negative: this.negative
  } 

  const campaigndetails = {
    campaignID: this.campaign,
    created: date
  }

const emgeekdetails = {
    userID: this.emgeek,
    created: date
  } */

 

}
/* function getByCampaignID(filterCampaigns, getcampaign) {
  if (getcampaign == '') return filterCampaigns
  return filterCampaigns.filter(({getcampaign}) => getcampaign.includes(getcampaign))
} */

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
  /* Import one of the Vue Flow Form CSS themes (optional) */
/*   @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-green.css';  */
 /*  @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css';  */
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css'; */
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal-jeff.css'; 
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.f-text{
  color:black!important;
}

.vff{
  color:black!important;
}

.f-required {
  display: inline; /* or display: none; to turn off */
}
</style>
