<template>
  <div class="about">
  
     <test2 />

   <!--   <test /> -->
      
  </div>
</template>


<script>
// @ is an alias to /src
import test from '@/components/test.vue' 
import test2 from '@/components/test2.vue'

export default {
  name: 'About',
  components: {
     test, 
    test2
  }
}
</script>
