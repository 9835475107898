/* import { initializeApp } from 'firebase/app';
import { getFirestore} from 'firebase/firestore/lite'; */

import { initializeApp } from 'firebase/app'
import {
  getFirestore, collection, onSnapshot,
  addDoc, deleteDoc, doc, getDoc, getDocs,
  query, where,
  orderBy, serverTimestamp,
  updateDoc
} from 'firebase/firestore'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword, signOut,
  onAuthStateChanged
} from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyCopBoETEdUxpYl2Ka8Xfnc3XxiWBmuOW4",
    authDomain: "sentimentemail-340b8.firebaseapp.com",
    databaseURL: "https://sentimentemail-340b8.firebaseio.com",
    projectId: "sentimentemail-340b8",
    storageBucket: "sentimentemail-340b8.appspot.com",
    messagingSenderId: "478362673140",
    appId: "1:478362673140:web:6ee1d519f1593f87dfe3a1",
    measurementId: "G-VXJR8W9SP1"
}

/* 
const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase); */

// Initialize Firebase

// init firebase
initializeApp(firebaseConfig)

// init services
const db = getFirestore()
const auth = getAuth()

   
/* db.enablePersistence({synchronizeTabs:true}); */


export default db;