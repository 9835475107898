<template>
  <div class="emailjobs">
  
     <Emailjobstest />

   <!--   <test /> -->
      
  </div>
</template>


<script>
// @ is an alias to /src
import Emailjobstest from '@/components/Emailjobstest.vue' 


export default {
  name: 'Emailjobs',
  components: {
     Emailjobstest
  }
}
</script>
